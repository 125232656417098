import RA from "../../../components/forms/Radio.module.css";

import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { BillingAccount, BillingAccountInvoice, BillingAccountUsage } from "@warrenio/api-spec/spec.oats.gen";
import { InvoiceStatusEnum } from "@warrenio/api-spec/spec/billingEnums";
import { filterFalse } from "@warrenio/utils/collections/filterFalse";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { useAtomValue } from "jotai/react";
import { Suspense, type ReactNode } from "react";
import { Radio, RadioGroup } from "react-aria-components";
import type { Action } from "../../../components/Action.tsx";
import { Badge } from "../../../components/Badge.tsx";
import { ContentPane } from "../../../components/ContentPane.tsx";
import { NavigateAfterDelete } from "../../../components/NavigateAfterDelete.tsx";
import { NoticeBlock } from "../../../components/NoticeBlock.tsx";
import { ResourceWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { Separator } from "../../../components/Separator.tsx";
import { Spacer } from "../../../components/Spacer.tsx";
import { ViewTitle } from "../../../components/Title.tsx";
import { Toolbar } from "../../../components/Toolbar.tsx";
import { VatText } from "../../../components/VatText.tsx";
import { WTabs, type WTabItem } from "../../../components/WTabs.tsx";
import { InfoTooltip, WTooltip } from "../../../components/WTooltip.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { WTooltipButton, type WTooltipButtonProps } from "../../../components/button/WToolButton.tsx";
import { EditableButton } from "../../../components/forms/EditableButton.tsx";
import { EditableText } from "../../../components/forms/EditableText.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { CurrencyBalance, CurrencyMonthly } from "../../../components/l10n/Currency.tsx";
import { LongDate } from "../../../components/l10n/DateFormat.tsx";
import { ContentLoadingSuspense } from "../../../components/loading/Loading.tsx";
import { DeleteModal } from "../../../components/modal/DeleteModal.tsx";
import { DetailsHolder, DetailsTable } from "../../../components/table/DetailsTable.tsx";
import { DetailsTableRow, type DetailsTableRowProps } from "../../../components/table/DetailsTableRow.tsx";
import { WSmartTable, WTable, WTableBody } from "../../../components/table/WTable.tsx";
import { useConfig } from "../../../config.ts";
import { useDeletableResource } from "../../../utils/query/useDeletableResource.tsx";
import { useQueryResultAtom, useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../../api/resourceTypeException.ts";
import { getResourceLink } from "../../api/resourceTypes.tsx";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import { WErrorBoundary } from "../../error/ErrorFallback.tsx";
import { CountryFlag } from "../../location/CountryFlag.tsx";
import { getIso2CountryInfo } from "../../location/locationCountry.ts";
import { getResourceName } from "../../resource/getResourceName.tsx";
import { ContactSupportButton } from "../../support/ContactSupportButton.tsx";
import { useThemeProps } from "../../theme/useTheme.ts";
import { AddPaymentMethodButton, TopUpNowButton } from "../ActionButtons.tsx";
import { AllFees } from "../AllFees.tsx";
import { AutomatedTopUpModal } from "../AutomatedTopUpModal.tsx";
import { accountCardMethodsAtom, getPrimaryCard, useAccountMethods } from "../BoundMethod.ts";
import { LimitedBillingAccountNoticeContent } from "../LimitedBillingAccountNotice.tsx";
import { LowBalanceNotificationsModal } from "../LowBalanceNotificationsModal.tsx";
import { OngoingBalance } from "../OngoingBalance.tsx";
import type { PaymentMethodId } from "../PaymentMethod.tsx";
import { ReportStatus } from "../ReportStatus.tsx";
import {
    activateBillingAccountCampaignMutation,
    deleteBillingAccountMutation,
    eBillingAccountQueryAtom,
    enableDisableAutomatedTopUpMutation,
    modifyLowBalanceNoticeSettingsMutation,
    saveBillingMethodsMutation,
    setDefaultBillingAccountMutation,
} from "../billingAccountQuery.ts";
import { baCardQueryAtom, deletePaymentCardMutation, setPaymentCardAsPrimaryMutation } from "../billingCardsQuery.ts";
import { baHistoryQueryAtom } from "../billingHistoryQuery.ts";
import { baInvoiceQueryAtom } from "../billingInvoicesQuery.ts";
import type { EBillingAccount } from "../billingLogic.tsx";
import { baUsageQueryAtom } from "../billingUsageQuery.ts";
import { InvoiceDueDate, InvoiceIssueDate, InvoicePeriod } from "../invoice/InvoiceDates.tsx";
import { createCurrentUsage } from "../invoice/invoiceLogic.tsx";
import { makeInvoiceLink } from "../invoice/links.ts";
import { baLinkedResourcesAtom, getLinkedPrice } from "../linkedResources.ts";
import { billingAccountEditLink } from "../links.ts";
import { CardShortInfo, PaymentLinkIcon, PaymentMethodIcon } from "../paymentMethodLogic.tsx";
import { ReferralBox } from "../referral/ReferralProgram.tsx";
import { baFieldsConfigAtom } from "./fieldsConfig.ts";
import { baLabels } from "./fieldsInfo.ts";

export function BillingAccountView({ baId }: { baId: number }) {
    //#region Hooks
    const accounts = useSuspenseQueryAtom(eBillingAccountQueryAtom);
    const billingAccount = useDeletableResource(() => getResourceById(accounts, baId, "billing_account"), null);

    const { invoiceAltTranslations } = useThemeProps();

    // Progressively load invoices tab (it will appear once query completes)
    const { data } = useQueryResultAtom(baInvoiceQueryAtom(baId));
    const invoices = data ? [...data.values()] : [];

    //#endregion

    if (billingAccount === undefined) {
        return <NavigateAfterDelete />;
    }

    const reports = invoices.filter((item: BillingAccountInvoice) => item.type === "cloud_services");
    const topupInvoices = invoices.filter((item: BillingAccountInvoice) => item.type === "credit_topup");
    const topUpInvoicesTab = topupInvoices.length > 0;

    const tabs = filterFalse<WTabItem>([
        billingAccount.canEnableAutomatedTopUp && {
            id: "automated",
            title: "Automated Top Up",
            content: <AutomatedTopUpContent billingAccount={billingAccount} />,
        },
        billingAccount.isPrePay && {
            id: "notifications",
            title: "Low Balance Notifications",
            content: <LowBalanceNotificationsContent billingAccount={billingAccount} />,
        },
        {
            id: "methods",
            title: "Payment Methods",
            content: <PaymentMethodsContent billingAccount={billingAccount} />,
        },
        billingAccount.canShareReferral && {
            id: "referral",
            title: "Referral Program",
            content: <ReferralProgramContent billingAccount={billingAccount} />,
        },
        {
            id: "reports",
            title: billingAccount.isPostPay ? invoiceAltTranslations!.invoicesTitle : "Reports",
            content: <ReportsContent billingAccount={billingAccount} items={reports} />,
        },
        topUpInvoicesTab && {
            id: "topup",
            title: invoiceAltTranslations!.topupInvoicesTitle,
            content: <TopUpInvoicesContent billingAccount={billingAccount} items={topupInvoices} />,
        },
        {
            id: "linked",
            title: "Linked Resources",
            content: (
                // TODO: Add a nice-looking error boundary that preserves the section header (and has no "Go to dashboard")

                // Use a suspense since the resources list might take a while to load
                <WErrorBoundary tags="LinkedResourcesContent">
                    <ContentLoadingSuspense>
                        <LinkedResourcesContent billingAccount={billingAccount} />
                    </ContentLoadingSuspense>
                </WErrorBoundary>
            ),
        },
        {
            id: "balance",
            title: "Balance History",
            content: <BalanceHistoryContent billingAccount={billingAccount} />,
        },
    ]);

    return (
        <>
            <BillingAccountToolbar billingAccount={billingAccount} />
            <BillingAccountDetails account={billingAccount} />
            <Separator />
            <WTabs autoSuspense allTab items={tabs} />
        </>
    );
}

function BillingAccountToolbar({ billingAccount }: { billingAccount: EBillingAccount }) {
    const { isDefault, isPrePay, hasVerifiedCard, id } = billingAccount;
    const setDefaultMutation = useStandardMutation(setDefaultBillingAccountMutation);

    async function setDefault() {
        await setDefaultMutation.mutateAsync(id);
    }

    return (
        <Toolbar>
            <WButton
                color="primary"
                size="bar"
                variant="ghost"
                action={async () => await setDefault()}
                isDisabled={isDefault || !hasVerifiedCard}
                icon="jp-icon-checkmark"
            >
                Set Default
            </WButton>

            <WButton
                color="primary"
                size="bar"
                variant="ghost"
                action={billingAccountEditLink(billingAccount)}
                icon="jp-icon-edit"
            >
                Edit
            </WButton>

            <DeleteBillingAccountModal billingAccount={billingAccount} />

            {isPrePay && (
                <TopUpNowButton size="bar" variant="ghost" account={billingAccount}>
                    Top Up Your Account
                </TopUpNowButton>
            )}
        </Toolbar>
    );
}

function DeleteBillingAccountModal({ billingAccount: { id, title } }: { billingAccount: EBillingAccount }) {
    const deleteMutation = useStandardMutation(deleteBillingAccountMutation);

    async function onDelete() {
        await deleteMutation.mutateAsync({ id });
    }

    return (
        <DeleteModal title="Delete Billing Account" modalAction={onDelete}>
            Deleting a billing account "{title}" will remove it permanently. Billing account can be deleted only if
            there are no unpaid resources attached to it and if it has no unspent credit.
        </DeleteModal>
    );
}

interface BFieldRowProps extends DetailsTableRowProps {
    title?: ReactNode;
    children?: ReactNode;
    field: keyof typeof baLabels;
    account?: BillingAccount;
}

function BFieldRow({ title, children, field, account, ...props }: BFieldRowProps) {
    const fieldsConfig = useAtomValue(baFieldsConfigAtom);

    if (!fieldsConfig[field]?.visible) {
        return null;
    }

    let displayedValue;
    if (children === undefined && account != null) {
        const value = account[field];
        displayedValue = value ? value : "-";
    } else {
        displayedValue = children;
    }

    return (
        <DetailsTableRow title={title ?? `${baLabels[field]}:`} {...props}>
            {displayedValue}
        </DetailsTableRow>
    );
}

function BillingAccountDetails({ account }: { account: EBillingAccount }) {
    const { id, title, isDefault, unpaidAmount, ongoingAmount } = account;
    const rawAccount = account.account;
    const { $type, country, discount_percentage } = rawAccount;

    const countryName = country && getIso2CountryInfo(country)?.name;
    const country3code = country && getIso2CountryInfo(country)?.code.iso3;

    const activateCampaignMutation = useStandardMutation(activateBillingAccountCampaignMutation);
    const { invoiceAltTranslations } = useThemeProps();

    async function insertCode(campaign_code: string) {
        await activateCampaignMutation.mutateAsync({ id, campaign_code });
    }

    return (
        <ContentPane className="VStack gap-4">
            <AccountDetailNotices billingAccount={account} />

            <ViewTitle
                title={title}
                // TODO: Remove global margins from all headings and instead use container-based layouts (eg. gap)
                // (otherwise it's impossible to lay out optional (nullable) components nicely without passing additional margin properties into every one of them)
                className="important:pb-0 important:min-h-auto"
            >
                {isDefault && (
                    // TODO: Create a `DefaultBadge` component
                    <span className="inline-flex items-center font-size-small pl-4">
                        <Badge inHeader noDot>
                            <MaskIcon className="jp-icon-checkmark size-0.75rem" />
                        </Badge>
                        <span className="pl-1">Default</span>
                    </span>
                )}
            </ViewTitle>

            <DetailsHolder>
                <DetailsTable>
                    <DetailsTableRow title="Account ID:">{id}</DetailsTableRow>

                    <DetailsTableRow title="Account name:">{title}</DetailsTableRow>

                    <DetailsTableRow title="Type:">
                        <ResourceWithIcon type={$type} />
                    </DetailsTableRow>

                    <BFieldRow field="company_name" account={rawAccount} />
                    <BFieldRow field="company_reg_code" account={rawAccount} />
                    <BFieldRow title={<VatText suffix="number" />} field="company_vat_number" account={rawAccount} />
                    <BFieldRow field="email" account={rawAccount} />
                    <BFieldRow field="customer_name" account={rawAccount} />
                    <BFieldRow field="customer_phone" account={rawAccount} />
                    <BFieldRow field="customer_id_number" account={rawAccount} />
                </DetailsTable>

                <Spacer />

                <DetailsTable>
                    <BFieldRow field="country">
                        <div className="flex gap-0.5rem">
                            <CountryFlag code={country3code ?? ""} />
                            {countryName}
                        </div>
                    </BFieldRow>
                    <BFieldRow field="county" account={rawAccount} />
                    <BFieldRow field="city" account={rawAccount} />
                    <BFieldRow field="address_line1" account={rawAccount} />
                    <BFieldRow field="post_index" account={rawAccount} />

                    <DetailsTableRow />

                    <DetailsTableRow
                        title="Ongoing balance:"
                        tooltip="Hourly updated usage for current billing period."
                    >
                        <OngoingBalance value={ongoingAmount} bold />
                    </DetailsTableRow>

                    {discount_percentage > 0 && (
                        <DetailsTableRow
                            title="Discount:"
                            tooltip="A discount has been applied for all services under this billing account."
                        >
                            <b className="color-success">-{discount_percentage}%</b>
                        </DetailsTableRow>
                    )}

                    {!!unpaidAmount && (
                        <DetailsTableRow title="Unpaid amount:" tooltip={invoiceAltTranslations!.unpaidAmountTooltip}>
                            <b className="color-error">
                                <CurrencyBalance value={-unpaidAmount} />
                            </b>
                        </DetailsTableRow>
                    )}

                    <DetailsTableRow
                        title="Campaign code:"
                        tooltip={
                            account.isPrePay && !account.isOpen
                                ? "Usage of promo code is activated only after a successful top up."
                                : undefined
                        }
                    >
                        <EditableText
                            placeholder="Insert code"
                            value=""
                            onChange={async (value) => await insertCode(value)}
                        />
                    </DetailsTableRow>
                </DetailsTable>
            </DetailsHolder>
        </ContentPane>
    );
}

function AutomatedTopUpContent({ billingAccount }: { billingAccount: EBillingAccount }) {
    const { minimumTopupAmount } = useConfig();
    const toggleAutomatedTopUpMutation = useStandardMutation(enableDisableAutomatedTopUpMutation);

    const { id } = billingAccount;
    const { is_recurring_payment_enabled, recurring_payment_threshold, recurring_payment_amount } =
        billingAccount.account;
    const cards = useSuspenseQueryAtom(baCardQueryAtom(id));

    const recurringPaymentThreshold = recurring_payment_threshold ?? minimumTopupAmount;
    const recurringPaymentAmount = recurring_payment_amount ?? minimumTopupAmount;

    const showAddCreditCard = !cards.length;
    const cardMethods = useSuspenseQueryAtom(accountCardMethodsAtom(id));
    const primaryCardMethod = getPrimaryCard(cardMethods);

    async function disableAutomatedTopUp() {
        await toggleAutomatedTopUpMutation.mutateAsync({ id, body: { is_recurring_payment_enabled: false } });
    }

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Automated Top Up</h2>

            <p className="pb-4 color-muted">
                We recommend automated top up as it minimizes the risk of running out of funds and thus affecting your
                server resources.
            </p>
            {showAddCreditCard && (
                <p className="pb-4 color-muted">Add credit card to payment methods for automated top up.</p>
            )}

            {is_recurring_payment_enabled && (
                <div className="flex items-start pb-4">
                    <DetailsTable>
                        <DetailsTableRow thWide title="When balance goes below:">
                            <AutomatedTopUpModal
                                billingAccount={billingAccount}
                                button={
                                    <EditableButton action={undefined}>
                                        <span className="font-size-small">
                                            <CurrencyBalance value={recurringPaymentThreshold} />
                                        </span>
                                    </EditableButton>
                                }
                            />
                        </DetailsTableRow>

                        <DetailsTableRow
                            thWide
                            title={
                                <>
                                    Automatically top up (min <CurrencyBalance value={minimumTopupAmount} />
                                    ):
                                </>
                            }
                        >
                            <AutomatedTopUpModal
                                billingAccount={billingAccount}
                                button={
                                    <EditableButton action={undefined}>
                                        <span className="font-size-small">
                                            <CurrencyBalance value={recurringPaymentAmount} />
                                        </span>
                                    </EditableButton>
                                }
                            />
                        </DetailsTableRow>
                    </DetailsTable>

                    <AllFees
                        billingAccount={billingAccount}
                        amount={recurringPaymentAmount}
                        primaryCardMethod={primaryCardMethod}
                    />
                </div>
            )}

            {/* TODO: Create a eg. `ButtonBar` component */}
            <div className="flex flex-wrap gap-2">
                {showAddCreditCard && (
                    <AddPaymentMethodButton onlyAutomated account={billingAccount} size="bar" variant="border">
                        Add Credit Card
                    </AddPaymentMethodButton>
                )}

                {is_recurring_payment_enabled ? (
                    <WButton
                        isDisabled={!cards.length || !(billingAccount.status === "ACTIVE")}
                        color="primary"
                        size="bar"
                        variant="border"
                        icon="jp-wrong-icon"
                        action={disableAutomatedTopUp}
                    >
                        Disable Automated Top Up
                    </WButton>
                ) : (
                    <AutomatedTopUpModal
                        billingAccount={billingAccount}
                        button={
                            <WButton
                                isDisabled={!cards.length}
                                color="primary"
                                size="bar"
                                variant="border"
                                icon="jp-icon-checkmark"
                                action={undefined}
                            >
                                Enable Automated Top Up
                            </WButton>
                        }
                    />
                )}
            </div>
        </ContentPane>
    );
}

function LowBalanceNotificationsContent({ billingAccount }: { billingAccount: EBillingAccount }) {
    const modifyMutation = useStandardMutation(modifyLowBalanceNoticeSettingsMutation);

    const { id } = billingAccount;
    const {
        low_balance_notice_settings: { threshold, is_enabled },
        is_recurring_payment_enabled,
    } = billingAccount.account;

    async function disableAutomatedTopUp() {
        await modifyMutation.mutateAsync({
            body: { billing_account_id: id, is_enabled: false, threshold: threshold! },
        });
    }

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Low Balance Notifications</h2>

            <p className="pb-4 color-muted">
                If low balance notifications are active and the balance falls below the threshold, you will be notified.
            </p>

            {is_recurring_payment_enabled && (
                <p className="pb-4">Low balance notification is not in effect because automated top up is activated.</p>
            )}

            {is_enabled && threshold !== undefined && !is_recurring_payment_enabled && (
                <div className="flex items-start pb-4">
                    <DetailsTable>
                        <DetailsTableRow thWide title="Notify when balance goes below:">
                            <LowBalanceNotificationsModal
                                billingAccount={billingAccount}
                                button={
                                    <EditableButton action={undefined}>
                                        <span className="font-size-small">
                                            <CurrencyBalance value={threshold} />
                                        </span>
                                    </EditableButton>
                                }
                            />
                        </DetailsTableRow>
                    </DetailsTable>
                </div>
            )}

            {!is_recurring_payment_enabled && (
                <div className="flex flex-wrap gap-2">
                    {is_enabled ? (
                        <WButton
                            isDisabled={is_recurring_payment_enabled}
                            color="primary"
                            size="bar"
                            variant="border"
                            icon="jp-wrong-icon"
                            action={disableAutomatedTopUp}
                        >
                            Disable Low Balance Notification
                        </WButton>
                    ) : (
                        <LowBalanceNotificationsModal
                            billingAccount={billingAccount}
                            button={
                                <WButton
                                    isDisabled={is_recurring_payment_enabled}
                                    color="primary"
                                    size="bar"
                                    variant="border"
                                    icon="jp-icon-checkmark"
                                    action={undefined}
                                >
                                    Enable Low Balance Notification
                                </WButton>
                            }
                        />
                    )}
                </div>
            )}
        </ContentPane>
    );
}

function PaymentMethodsContent({ billingAccount }: { billingAccount: EBillingAccount }) {
    //#region Hooks
    const { cardMethods, otherMethods } = useAccountMethods(billingAccount);
    const cards = cardMethods.map((m) => m.card);
    const primaryCard = cards.find((item) => item.is_primary);
    //#endregion

    const showCardCols = cardMethods.length > 0;

    const onDeleteMethodMutation = useStandardMutation(saveBillingMethodsMutation);
    const onDeleteCardMutation = useStandardMutation(deletePaymentCardMutation);
    const onSetPrimaryCardMutation = useStandardMutation(setPaymentCardAsPrimaryMutation);

    async function onDeleteMethod(methodId: PaymentMethodId) {
        const remainingMethodIds = otherMethods.filter((om) => om.id !== methodId).map((m) => m.id);
        await onDeleteMethodMutation.mutateAsync({
            billing_account_id: billingAccount.id,
            method_ids: remainingMethodIds,
        });
    }

    async function onDeleteCard(paymentObjectId: number) {
        await onDeleteCardMutation.mutateAsync({
            payment_object_id: paymentObjectId,
        });
    }

    async function onSetPrimaryCard(paymentObjectId: number) {
        await onSetPrimaryCardMutation.mutateAsync({
            payment_object_id: paymentObjectId,
        });
    }

    const tableHead = (
        <thead>
            <tr>
                <th>Method</th>
                <th aria-label="Icon" />
                {showCardCols && <th>Primary</th>}
                {showCardCols && <th>Status</th>}
                <th aria-label="Actions" />
            </tr>
        </thead>
    );

    const cardItems = cardMethods.map((method) => {
        const { card } = method;
        const additional_data = card.parsedAdditionalData;

        return (
            <tr key={method.id}>
                <td>
                    <CardShortInfo card={card} />
                </td>
                <td>
                    <PaymentMethodIcon card={card} />
                </td>
                <td>
                    {card.is_verified ? (
                        <Radio
                            className={RA.Radio}
                            value={String(card.id)}
                            isDisabled={onSetPrimaryCardMutation.isPending}
                        >
                            Primary
                        </Radio>
                    ) : (
                        "-"
                    )}
                </td>
                <td>
                    {card.is_verified ? (
                        <Badge color="success">Verified</Badge>
                    ) : (
                        <Badge color="error">Unverified</Badge>
                    )}
                </td>
                <td className="text-right">
                    <DeleteModal inTable title="Delete Payment Method" modalAction={() => onDeleteCard(card.id)}>
                        {additional_data.type === "paying_by_invoice" ? (
                            <>
                                Deleting a Paying By Invoice method will permanently remove it from the billing account.
                            </>
                        ) : (
                            <>
                                Deleting a credit card "**** **** **** {additional_data.last4}" will permanently remove
                                it from the billing account.
                            </>
                        )}
                    </DeleteModal>
                </td>
            </tr>
        );
    });

    const methodItems = otherMethods.map(({ id, method }) => {
        return (
            <tr key={id}>
                <td>{method.name}</td>
                <td>
                    <PaymentLinkIcon icon={method.id} />
                </td>
                {showCardCols && <td>-</td>}
                {showCardCols && <td>-</td>}
                <td className="text-right">
                    <DeleteModal inTable title="Delete Payment Method" modalAction={() => onDeleteMethod(method.id)}>
                        Deleting a payment method "{method.name}" will remove it from the billing account.
                    </DeleteModal>
                </td>
            </tr>
        );
    });

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Payment Methods</h2>

            <RadioGroup
                aria-label="Set primary"
                onChange={(value) => {
                    void onSetPrimaryCard(Number(value));
                }}
                value={primaryCard?.id != null ? String(primaryCard?.id) : null}
            >
                <WTable afterTable={<AddPaymentMethodButton account={billingAccount} size="bar" variant="border" />}>
                    {tableHead}
                    <WTableBody>
                        {cardItems}
                        {methodItems}
                    </WTableBody>
                </WTable>
            </RadioGroup>
        </ContentPane>
    );
}

function ReferralProgramContent({ billingAccount }: { billingAccount: EBillingAccount }) {
    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Referral Program</h2>
            <ReferralBox
                account={billingAccount}
                firstItem="You can earn bonus credits to your billing account by sharing your unique referral code"
            />
        </ContentPane>
    );
}

function mergeInvoicesWithUsage(
    invoices: BillingAccountInvoice[],
    usage: BillingAccountUsage[],
    billingAccount: BillingAccount,
): BillingAccountInvoice[] {
    const remainingInvoices = invoices.filter((inv) => inv.status !== InvoiceStatusEnum.IN_PROGRESS);
    const currentUsage = createCurrentUsage(usage, billingAccount);

    return [currentUsage, ...remainingInvoices];
}

const ViewLink = ({ invoice, viewLink }: { invoice: BillingAccountInvoice; viewLink: Action }) => (
    <WTooltip text="View">
        <WButton color="primary" size="xs" variant="ghost" action={viewLink}>
            {invoice.padded_id ? `#${invoice.padded_id}` : invoice.name}
        </WButton>
    </WTooltip>
);

function ReportsContent({
    billingAccount,
    items,
}: {
    billingAccount: EBillingAccount;
    items: BillingAccountInvoice[];
}) {
    const { id, isPostPay } = billingAccount;

    //#region Hooks
    const usage = useSuspenseQueryAtom(baUsageQueryAtom(id));
    const { invoiceAltTranslations } = useThemeProps();
    //#endregion

    const showDueDateColumn = isPostPay;
    const withCurrentItems = mergeInvoicesWithUsage(items, usage, billingAccount.account);

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">{isPostPay ? invoiceAltTranslations!.invoicesTitle : "Reports"}</h2>

            <WSmartTable
                items={withCurrentItems}
                hasViewAllButton
                head={
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Status</th>
                            <th>Period</th>
                            {showDueDateColumn && <th>Due Date</th>}
                            <th>
                                <InfoTooltip
                                    text={isPostPay ? <VatText prefix="Total excl." /> : <VatText prefix="Excl." />}
                                >
                                    Usage
                                </InfoTooltip>
                            </th>
                            <th />
                        </tr>
                    </thead>
                }
            >
                {(item) => {
                    const viewLink = makeInvoiceLink(item, billingAccount);
                    const { name, status, padded_id, totals } = item;
                    const usage = isPostPay ? totals.total_before_tax : (totals.subtotal_resource_usage ?? 0);
                    // XXX: Why is this checking `name`?
                    const showStatus = isPostPay || !!name;

                    return (
                        <tr key={item.id}>
                            <td>
                                <ViewLink invoice={item} viewLink={viewLink} />
                            </td>
                            <td>{showStatus && <ReportStatus value={status} />}</td>
                            <td>{padded_id ? <InvoicePeriod invoice={item} /> : "-"}</td>
                            {showDueDateColumn && (
                                <td>
                                    <InvoiceDueDate invoice={item} />
                                </td>
                            )}
                            <td>{usage ? <CurrencyBalance value={usage} /> : "-"}</td>
                            <td className="text-right">
                                <ViewButton action={viewLink} />
                            </td>
                        </tr>
                    );
                }}
            </WSmartTable>
        </ContentPane>
    );
}

const ViewButton = (props: Omit<WTooltipButtonProps, "title">) => (
    <WTooltipButton title="View" icon="jp-icon-folder" color="primary" size="xs" variant="ghost" {...props} />
);

function TopUpInvoicesContent({
    billingAccount,
    items,
}: {
    billingAccount: EBillingAccount;
    items: BillingAccountInvoice[];
}) {
    //#region Hooks
    const { invoiceAltTranslations } = useThemeProps();
    //#endregion
    return (
        <ContentPane>
            <h2 className="font-size-subtitle">{invoiceAltTranslations!.topupInvoicesTitle}</h2>

            <WSmartTable
                items={items}
                hasViewAllButton
                head={
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Status</th>
                            <th>Issued</th>
                            <th>Totals</th>
                            <th />
                        </tr>
                    </thead>
                }
            >
                {(item) => {
                    const viewLink = makeInvoiceLink(item, billingAccount);
                    return (
                        <tr key={item.id}>
                            <td>
                                <ViewLink invoice={item} viewLink={viewLink} />
                            </td>
                            <td>
                                <ReportStatus value={item.status} />
                            </td>
                            <td>{item.padded_id ? <InvoiceIssueDate invoice={item} /> : "-"}</td>
                            <td>
                                <CurrencyBalance value={item.totals.total} />
                            </td>
                            <td className="text-right">
                                <ViewButton action={viewLink} />
                            </td>
                        </tr>
                    );
                }}
            </WSmartTable>
        </ContentPane>
    );
}

function LinkedResourcesContent({ billingAccount: { id } }: { billingAccount: EBillingAccount }) {
    const linked = useSuspenseQueryAtom(baLinkedResourcesAtom(id));

    let linkedTotalPrice = 0;

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Linked Resources</h2>

            <WTable>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Est. Monthly Cost</th>
                        <th />
                    </tr>
                </thead>
                <WTableBody>
                    {linked.map((item, index) => {
                        const itemPrice = getLinkedPrice(item);
                        const hourly = itemPrice?.hourly;

                        linkedTotalPrice += hourly ?? 0;

                        // TODO: Add a `getResourceId()` function for the `key` prop
                        return (
                            <tr key={index}>
                                <td>
                                    <WTooltip text="View">
                                        <WButton
                                            color="primary"
                                            size="xs"
                                            variant="ghost"
                                            action={getResourceLink(item)}
                                        >
                                            {getResourceName(item)}
                                        </WButton>
                                    </WTooltip>
                                </td>
                                <td>
                                    <ResourceWithIcon type={item.$type} />
                                </td>
                                <td>{hourly != null ? <CurrencyMonthly value={hourly} /> : "-"}</td>
                                <td />
                            </tr>
                        );
                    })}
                </WTableBody>

                {!!linked.length && (
                    <tfoot>
                        <tr>
                            <td />
                            <td className="text-right">Est. Total:</td>
                            <td>
                                <CurrencyMonthly value={linkedTotalPrice} />
                            </td>
                            <td />
                        </tr>
                    </tfoot>
                )}
            </WTable>
        </ContentPane>
    );
}

function BalanceHistoryContent({ billingAccount: { id } }: { billingAccount: EBillingAccount }) {
    const items = useSuspenseQueryAtom(baHistoryQueryAtom(id));

    return (
        <ContentPane>
            <h2 className="font-size-subtitle">Balance History</h2>

            <WSmartTable
                items={items}
                hasViewAllButton
                head={
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                }
            >
                {(item) => {
                    return (
                        <tr key={item.id}>
                            <td>{item.created ? <LongDate date={apiUnixToDate(item.created)} /> : "-"}</td>
                            <td className={item.amount > 0 ? "color-success" : "color-error"}>
                                <CurrencyBalance value={item.amount} />
                            </td>
                            <td>{item.description}</td>
                        </tr>
                    );
                }}
            </WSmartTable>
        </ContentPane>
    );
}

function AccountNotice({ billingAccount }: { billingAccount: EBillingAccount }) {
    const {
        status,
        account: { suspend_reason },
    } = billingAccount;

    switch (status) {
        case "SUSPENDED":
            return (
                <NoticeBlock color="error" icon="jp-warning-icon" button={<ContactSupportButton color="error" />}>
                    <b className="uppercase">Billing Account Suspended</b>
                    <br />
                    {suspend_reason}
                </NoticeBlock>
            );
        case "PENDING_VALIDATION":
            return (
                <NoticeBlock color="primary" icon="jp-warning-icon">
                    <b className="uppercase">Account details are being validated.</b>
                    <br />
                    You will be able to set up payment methods and use cloud services once the validation is complete.
                </NoticeBlock>
            );
        case "ACTIVE":
            return null;
        default:
            return exhaustiveSwitchCheck(status);
    }
}

// TODO: Combine with desktop billing account notices block
export function AccountDetailNotices({ billingAccount }: { billingAccount: EBillingAccount }) {
    return (
        <>
            <AccountNotice billingAccount={billingAccount} />
            <Suspense>
                <LimitedBillingAccountNoticeContent account={billingAccount} />
            </Suspense>
        </>
    );
}
